<template>
    <ts-panel>
        <ts-panel-wrapper class="tw-space-y-2">
            <h4
                class="tw-mb-0 tw-p-1 tw-uppercase tw-font-semibold tw-text-gray-400 tw-underline"
            >
                {{ $t("driverProfile.signUp") }}
            </h4>
            <div class="row tw-space-y-2 sm:tw-space-y-0">
                <div class="col-md-6 tw-space-y-1">
                    <label>{{ $t("driverProfile.registerWith") }}</label>
                    <select class="form-control" 
											v-model="register"
											@change="registerTypeChange"
										>
                        <option value="phone">{{
                            $t("driverProfile.phoneNumber")
                        }}</option>
                        <option value="name">{{
                            $t("driverProfile.name")
                        }}</option>
                    </select>
                </div>
                <div class="col-md-6 tw-space-y-1">
                    <label class="required">{{
                        $t("driverProfile.userName")
                    }}</label>
                    <input
                        v-model="model.user_name"
                        type="text"
                        class="form-control"
                        :class="{ 'is-invalid': errors.has('user_name') }"
                        :placeholder="$t('driverProfile.userName')"
                    />
                    <div
                        class="invalid-feedback"
                        v-if="errors.has('user_name')"
                    >
                        {{ errors.first("user_name") }}
                    </div>
                </div>
            </div>
            <div class="row tw-space-y-2 sm:tw-space-y-0">
                <div class="col-md-6 tw-space-y-1">
                    <label class="required">{{
                        $t("driverProfile.password")
                    }}</label>
                    <input
                        v-model="model.password"
                        type="password"
                        class="form-control"
                        :class="{ 'is-invalid': errors.has('password') }"
                        :placeholder="$t('driverProfile.password')"
                    />
                    <div class="invalid-feedback" v-if="errors.has('password')">
                        {{ errors.first("password") }}
                    </div>
                </div>
                <div class="col-md-6 tw-space-y-1">
                    <label class="required">{{
                        $t("driverProfile.confirm")
                    }}</label>
                    <input
                        v-model="model.password_confirmation"
                        type="password"
                        class="form-control"
                        :class="{
                            'is-invalid': errors.has('password_confirmation')
                        }"
                        :placeholder="$t('driverProfile.confirm')"
                    />
                    <div
                        class="invalid-feedback"
                        v-if="errors.has('password_confirmation')"
                    >
                        {{ errors.first("password_confirmation") }}
                    </div>
                </div>
            </div>
        </ts-panel-wrapper>
    </ts-panel>
</template>
<script>
import { Errors } from "form-backend-validation";
export default {
    name: "user",
    props: ["value", "validate"],
    data() {
        return {
            errors: new Errors(),
            register: "phone",
            model: {}
        };
    },
    created() {
        this.model = this.value;
    },
		methods: {
			registerTypeChange() {
					if(this.register == 'name')
					{
						this.model.user_name = this.model.driver_name ? (this.model.driver_name).toLowerCase().split(' ').join('.') : ''
					}else{
						this.model.user_name = this.model.phone1
					}
			}
		},
    watch: {
				'model.driver_name': function(value){
					if(this.register == 'name')
					{
						this.model.user_name = value ? (this.model.driver_name).toLowerCase().split(' ').join('.') : ''
					}
				},
				'model.phone1': function(value){
					if(this.register == 'phone')
					{
						this.model.user_name = value
					}
				},
        validate: function(error) {
            this.errors = new Errors(error.errors);
        }
    }
};
</script>
